import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';
import { DispatchFn } from '@models/redux';
import { UploadedFile } from '@models/core';
import { Col, Row } from '@components/grid';
import { Modal, MFC, ModalTitle, ModalContent } from '@modules/modal';
import { DownloadFile, DownloadFileWrapper } from '../../../systems/downloadFile';
import {
  CoinInfo,
  VaultBalanceDetailsHistoryItem as HistoryItem,
  VaultInfo,
} from '../vaults-api.service';
import { vaultBalanceDetailsSelectors as detailsSelectors } from './store/selectors';
import { vaultBalanceDetailsUserActions as detailsUserActions } from './store/user.acions';
import { OnModalOpenedPayload } from './store/model';
import s from './vault-balance-detail.module.scss';

const mapStateToProps = (root) => ({
  history: detailsSelectors.balanceHistory(root),
  latestBalance: detailsSelectors.latestBalance(root),
  latestBalanceCreatedTime: detailsSelectors.latestBalanceCreatedTime(root),
  latestBalanceCreator: detailsSelectors.latestBalanceCreator(root),
  latestBalanceReport: detailsSelectors.latestBalanceReport(root),
  lockUI: detailsSelectors.lockUI(root),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseModalClicked: () => dispatch(detailsUserActions.onCloseModalClicked()),
  onModalLeft: () => dispatch(detailsUserActions.onModalLeft()),
  onModalOpened: (payload: OnModalOpenedPayload) =>
    dispatch(detailsUserActions.onModalOpened(payload)),
});

const ConnectedVaultBalanceDetailModal: MFC<{
  coin: CoinInfo;
  history: Array<HistoryItem>;
  latestBalance: HistoryItem;
  latestBalanceCreatedTime: string;
  latestBalanceCreator: string;
  latestBalanceReport: UploadedFile;
  lockUI: boolean;
  onCloseModalClicked: DispatchFn<void>;
  onModalLeft: DispatchFn<void>;
  onModalOpened: DispatchFn<OnModalOpenedPayload>;
  vault: VaultInfo;
}> = ({
  coin,
  history,
  isVisible,
  latestBalance,
  latestBalanceCreatedTime,
  latestBalanceCreator,
  latestBalanceReport,
  lockUI,
  onCloseModalClicked,
  onModalLeft,
  onModalOpened,
  vault,
}) => {
  useEffect(() => {
    onModalOpened({ coin, vault });
    return () => onModalLeft();
  }, []); // eslint-disable-line

  return (
    <Modal isVisible={isVisible} disabled={lockUI} loading={lockUI} onClose={onCloseModalClicked}>
      <ModalTitle>
        <Trans id="vault_balance_detail_modal.title">
          {vault.warehouseCompany}, {vault.city} {coin.name} Balance
        </Trans>
      </ModalTitle>

      <ModalContent>
        <div className="mb-md">
          <div className="mb-sm">
            <Trans id="vault_balance_detail_modal.physical">Physical:</Trans>
          </div>
          <div className="big-text">
            {latestBalance?.amount} {latestBalance?.unit?.label}
          </div>
        </div>

        <div className="mb-sm">
          <Trans id="vault_balance_detail_modal.last_balance">Last confirmed balance:</Trans>
        </div>

        <div className="mb-md">
          <Row>
            <Col sm={8}>
              <span>{latestBalanceCreatedTime}</span>
              &nbsp;
              <span className="op-text">
                <Trans id="vault_balance_detail_modal.by">by</Trans>
              </span>
              &nbsp;
              <span>{latestBalanceCreator}</span>
            </Col>

            <Col sm={4}>
              {!latestBalanceReport ? null : (
                <DownloadFileWrapper>
                  <DownloadFile uploadFile={latestBalanceReport} />
                </DownloadFileWrapper>
              )}
            </Col>
          </Row>
        </div>

        {!history.length ? null : (
          <>
            <div className="mb-md">
              <Trans id="vault_balance_detail_modal.previous_balance">Previous balance</Trans>
            </div>

            {history.map((item: HistoryItem) => (
              <Row className="mb-xs" key={item.id}>
                <Col sm={4} className={s.balanceCol}>
                  {item?.vaultBalance?.createdAt}
                </Col>
                <Col sm={4} className={s.balanceCol}>
                  {item?.amount} {item?.unit?.label}
                </Col>
                <Col sm={4} className={s.balanceCol}>
                  {!item?.report ? null : (
                    <DownloadFileWrapper>
                      <DownloadFile notImage={true} uploadFile={item.report} />
                    </DownloadFileWrapper>
                  )}
                </Col>
              </Row>
            ))}
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export const VaultBalanceDetailModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedVaultBalanceDetailModal);
