import { saveAs } from 'file-saver';
import { sharedApi } from '@services/api';

import { toggleGlobalPreloaderAction } from '@config/core';
import { privateApi } from '../../utils/api';
import { formChangeAction } from '../../actions/formAction';

export const uploadFile = (formName, event) => async (dispatch) => {
  dispatch(toggleGlobalPreloaderAction(true));
  const target = event.target;
  const data = await sharedApi.uploadFile(target);
  dispatch(toggleGlobalPreloaderAction(false));
  dispatch(formChangeAction(formName, target.name, data));
};

export const downloadFile = (uploadFile) => async (dispatch) => {
  await download(uploadFile.id, uploadFile.name);
};

export const download = async (fileId, name) => {
  let response = await privateApi.get(`/downloadFile/${fileId}`, { responseType: 'blob' });
  saveAs(response.data, name);
};

export const removeFile = (formName, fieldName) => (dispatch) =>
  dispatch(formChangeAction(formName, fieldName, null));
