import { useEffect, useState } from 'react';
import { defineMessage } from '@lingui/macro';

import { useShowPreloaderLine } from '@components/layouts/dark/provider';

import {
  useActiveOfferPrices,
  useIsApprovedBidOrdersLoading,
  useIsTickerLoading,
  useSelectedPair,
  useStableBalance,
} from '../detailed/provider';
import { useI18n } from 'containers/services/commonService';
import { download } from 'containers/services/uploadFileService';
import { useBasicSMFee, useTabsHeight } from './service';

export const useModel = () => {
  const { i18n, lang } = useI18n();
  const selectedPair = useSelectedPair();
  const tabsHeight = useTabsHeight();
  const [selectedOfferPrice, setSelectedOfferPrice] = useState();
  const [stableBalance, isStableBalanceLoading] = useStableBalance();
  const selectedPairStableBalance =
    selectedPair &&
    stableBalance
      ?.filter((balance) => !!balance.currency)
      .find((balance) => balance.currency.code === selectedPair.currency.code);
  const [isWeekPriceLoading, setIsWeekPriceLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [physicalRedemption, setPhysicalRedemption] = useState('');
  const isPhysicalRedemption = !!selectedPair?.coin?.coinInfo?.physicalRedemption;
  const [basicSMFee, isBasicSMFeeLoading] = useBasicSMFee();
  const secondaryMarketFee = selectedPair?.coin?.coinInfo?.secondaryMarketFee || basicSMFee;

  useEffect(() => {
    setPhysicalRedemption(
      isPhysicalRedemption
        ? i18n._(
            defineMessage({
              id: 'digital_metals.general.physical_redemption.available',
              message: 'Available',
            })
          )
        : i18n._(
            defineMessage({
              id: 'digital_metals.general.physical_redemption.not_available',
              message: 'Not available',
            })
          )
    );
  }, [isPhysicalRedemption, lang]); // eslint-disable-line

  const onDownloadInfoFileClicked = async (file) => {
    if (isFileLoading) return;

    setIsFileLoading(true);
    await download(file.id, file.name);
    setIsFileLoading(false);
  };

  const isTickerLoading = useIsTickerLoading();
  const [, isActiveOfferPricesLoading] = useActiveOfferPrices();
  const showPreloaderLine = useShowPreloaderLine();
  const isApprovedBidOrdersLoading = useIsApprovedBidOrdersLoading();

  useEffect(
    () =>
      showPreloaderLine(
        isTickerLoading ||
          isActiveOfferPricesLoading ||
          isApprovedBidOrdersLoading ||
          isStableBalanceLoading ||
          isWeekPriceLoading ||
          isFileLoading ||
          isBasicSMFeeLoading
      ),
    [
      isTickerLoading,
      isActiveOfferPricesLoading,
      isApprovedBidOrdersLoading,
      isStableBalanceLoading,
      isWeekPriceLoading,
      isFileLoading,
      isBasicSMFeeLoading,
    ] // eslint-disable-line
  );

  return {
    i18n,
    tabsHeight,
    selectedOfferPrice,
    selectedPairStableBalance,
    setIsWeekPriceLoading,
    selectedPair,
    setSelectedOfferPrice,
    onDownloadInfoFileClicked,
    physicalRedemption,
    isTickerLoading,
    secondaryMarketFee,
  };
};
