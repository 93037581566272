import React, { useState } from 'react';
import { getFile } from '../../services/commonService';

export const useImageModel = (uploadFile, onError) => {
  const [data, setData] = useState(null);
  const [isError, setError] = useState(false);

  React.useEffect(() => {
    getFile(`/downloadFile/${uploadFile.id}`)
      .then((data) => {
        let reader = new FileReader();
        reader.onload = () => setData(reader.result);
        reader.readAsDataURL(data.data);
      })
      .catch((error) => {
        onError && onError(error);
        setError(true);
      });
  }, []);

  const src = data;

  return { data, isError, src };
};
