import { useState } from 'react';
import { useParams } from 'react-router';

import { handleBackendError } from '@modules/notify';
import api from './api';

export const useModel = () => {
  const [isBillLoading, setBillLoading] = useState<boolean>(false);
  const [isPassportLoading, setPassportLoading] = useState<boolean>(false);
  const { userId } = useParams<{ userId: string }>();

  const onDownloadBillClicked = async () => {
    setBillLoading(true);
    try {
      await api.downloadVerificationDocument(+userId, 'UTILITY_BILL');
    } catch (error) {
      handleBackendError(error);
    }
    setBillLoading(false);
  };

  const onDownloadPassportClicked = async () => {
    setPassportLoading(true);
    try {
      await api.downloadVerificationDocument(+userId, 'IDENTIFIER_DOCUMENT');
    } catch (error) {
      handleBackendError(error);
    }
    setPassportLoading(false);
  };

  return {
    isBillLoading,
    isPassportLoading,
    onDownloadBillClicked,
    onDownloadPassportClicked,
  };
};
